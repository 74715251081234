<template>
  <div>
    <v-card style="background-color: #fafafa">
      <v-card-text>
        <v-alert outline color="error" v-if="error">{{ error }}</v-alert>
        <v-layout>
          <div
            class="heading orange--text pb-2"
            style="text-align: initial"
            v-if="this.milestoneTrack.milestone.length > 0"
          >
            Milestone Information
          </div>
          <v-spacer />
          <changeMilestone
            v-if="
              (this.milestoneTrack.milestone.length > 0 &&
                detail.assignmentStatus == 2) ||
              (this.milestoneTrack.milestone.length > 0 &&
                detail.assignmentStatus === 5) ||
              (this.milestoneTrack.milestone.length > 0 &&
                detail.assignmentStatus === 6) ||
              (this.milestoneTrack.milestone.length > 0 &&
                detail.assignmentStatus === 7)
            "
            v-permissions="'change-milestone'"
            :assignmentDetails="detail"
          ></changeMilestone>
          <ViewComment
            v-permissions="'view-comments-jobs'"
            :job-id="detail.jobId"
            :assignmentId="detail.assignmentId"
          />
        </v-layout>

        <v-spacer class="w1300px">
          <v-layout v-if="this.milestoneTrack.milestone.length > 0">
            <v-stepper>
              <v-stepper-header>
                <template v-for="(milestone, n) in milestoneTrack.milestone">
                  <!-- {{ milestoneTrack.milestone[n + 1].isVisaExpiry }} -->
                  <v-stepper-step
                    :key="`${n + 1}-step`"
                    :step="milestoneTrack.milestone.length"
                    v-if="milestone.isChecked"
                    :disabled="detail.assignmentStatus == -1"
                    complete
                    style="min-width: 270px !important"
                  >
                    <!-- {{ milestone }}
                    {{ milestoneTrack.milestone[n + 1].isVisaExpiry }} -->

                    <!-- DUe to change milestone live -->
                    <!-- <div
                      class="pt-0"
                      v-if="
                        detail.assignmentStatus == 5 ||
                        detail.assignmentStatus == 6
                      "
                    >
                      <v-icon
                        flat
                        prepend-icon
                        v-if="checkLastMilestoneStatus(n)"
                        color="red"
                        v-permissions="'uncheck-milestone'"
                        left
                        class="widthIcon"
                        @click="
                          unmarkMilestone(
                            detail.assignmentId,
                            detail.milestoneData['_id'],
                            milestone.mileStoneId,
                            milestone.mileStoneName
                          )
                        "
                      >
                        cancel
                      </v-icon>

                  
                    </div> -->
                    <div class="f-12 muted" v-if="milestone.usersData.length">
                      <v-icon size="15" dense>person</v-icon>
                      {{ milestone.usersData[0].name }}({{
                        milestone.usersData[0].employeeID
                      }})
                    </div>

                    <div class="f-12 muted" v-if="milestone.driverData">
                      <v-icon size="15" dense>person</v-icon>
                      <!-- {{ milestone }} -->
                      {{ milestone.driverData.name }}({{
                        milestone.driverData.driverId
                      }})
                    </div>

                    <div class="f-12 muted" v-if="milestone.transporterData">
                      <v-icon size="15" dense>person</v-icon>
                      {{ milestone.transporterData.transporterName }}({{
                        milestone.transporterData.transporterId
                      }})
                    </div>
                    <div class="f-12 muted" v-if="milestone.updatedDate">
                      <v-icon size="15" dense>watch_later</v-icon>
                      Actual Milestone Date :
                      {{ getTimes(milestone.updatedDate) }}
                    </div>

                    <v-icon
                      flat
                      prepend-icon
                      color="blue"
                      v-if="detail.assignmentStatus != -1"
                      :disabled="viewOnly == 'view'"
                      @click.native="
                        addCommentx.error = '';
                        editMilestoneComment(
                          milestoneTrack.assId,
                          milestone.mileStoneId,
                          n
                        );
                      "
                      title="Edit Comment"
                      class="viewDetail pt-1"
                      style="cursor: pointer"
                      >comments</v-icon
                    >
                    <v-icon
                      flat
                      prepend-icon
                      color="blue"
                      v-else
                      @click.native="
                        addCommentx.error = '';
                        editMilestoneComment(
                          milestoneTrack.assId,
                          milestone.mileStoneId,
                          n
                        );
                      "
                      title="Edit Comment"
                      class="viewDetail pt-1"
                      style="cursor: pointer"
                      :disabled="viewOnly == 'view'"
                      >comments</v-icon
                    >
                  </v-stepper-step>

                  <v-stepper-step
                    :key="`${n + 1}-step`"
                    :step="n + 1"
                    class="step-clickable"
                    editable
                    v-else-if="
                      currentMilestone == n && detail.assignmentStatus != -1
                    "
                    :disabled="profile.jobStatus == 10 || viewOnly == 'view'"
                    @click="openInfoMilestone(milestone.mileStoneId, n)"
                    >{{ milestone.mileStoneName }}</v-stepper-step
                  >

                  <v-stepper-step :key="`${n + 1}-step`" :step="n + 1" v-else>{{
                    milestone.mileStoneName
                  }}</v-stepper-step>

                  <v-divider
                    class="divider"
                    v-if="n + 2 <= milestoneTrack.milestone.length"
                    :key="n + 1"
                    >{{ n }}</v-divider
                  >
                </template>
              </v-stepper-header>
            </v-stepper>
          </v-layout>
        </v-spacer>
        <br />
        <v-card-actions
          v-if="this.milestoneTrack.milestone.length > 0"
          style="display: block"
        >
          <!-- <v-layout>
            <v-spacer />

            <ViewComment
              v-permissions="'view-comments-jobs'"
              :job-id="detail.jobId"
              :assignmentId="detail.assignmentId"
            />
          </v-layout> -->
        </v-card-actions>
        <v-divider
          class="my-1 mb-4"
          v-if="this.milestoneTrack.milestone.length > 0"
        />
        <div
          v-if="
            (working != 'PAK' && status != 1) ||
            (working != 'PAK' && status == 1) ||
            (working != 'PAK' && status != 1)
          "
          class="heading orange--text pb-2"
          style="text-align: initial"
        >
          Assignment Information
        </div>
        <v-layout row> </v-layout>
        <v-layout row class="py-4" style="text-align: initial">
          <v-flex xs4 v-if="detail.wayBillNo">
            <div class="subheading muted">Waybill Number</div>
            <div class="heading">{{ detail.wayBillNo }}</div>
          </v-flex>
          <v-flex xs4 v-if="detail.paymentAmount">
            <div class="subheading muted">Driver Amount</div>
            <div class="heading">
              {{ detail.paymentCurrency + " " + detail.paymentAmount }}
            </div>
          </v-flex>
          <v-flex xs4 v-if="detail.invoiceAmount">
            <div class="subheading muted">Invoice Amount(Assign. Level)</div>
            <div class="heading">
              {{ detail.paymentCurrency + " " + detail.invoiceAmount }}
            </div>
          </v-flex>
          <v-flex
            xs4
            v-permissions="'generate-purchase-invoice'"
            v-if="
              detail.isReadyToGeneratePurchaseInvoice &&
              !detail.isPurchaseInvoiceGenerated &&
              !detail.isPurchaseOrderInQueue &&
              profile.workOrderCompleted &&
              working != 'PAK' &&
              working != 'OMAN' &&
              detail.assignmentStatus != -1
            "
          >
            <div class="subheading muted">Generate Purchase Invoice</div>
            <div class="heading">
              <div v-if="detail.showPurchaseInvoiceButton">
                <button
                  @click="openPiPopup(detail._id)"
                  v-permissions="'generate-purchase-invoice'"
                  target="_blank"
                  v-if="!detail.isPurchaseInvoviceBackgroundProcess"
                  :disabled="loading || viewOnly == 'view'"
                  class="track-ass-btn m-t-10 m-l-10 orange-button"
                  title="Generate"
                >
                  Generate
                </button>
                <div
                  v-if="detail.isPurchaseInvoviceBackgroundProcess"
                  style="word-break: break-word"
                  class="subheading muted"
                >
                  Purchase Invoice can't be generated, Background process is
                  ongoing.
                </div>
              </div>
              <div v-else>N.A</div>
            </div>
          </v-flex>

          <v-flex
            xs4
            v-if="
              detail.invoiceNumber &&
              detail.isPurchaseInvoiceGenerated &&
              profile.workOrderCompleted &&
              working != 'PAK' &&
              (detail.assignmentStatus != 1 || detail.assignmentStatus != -1)
            "
          >
            <div v-if="working != 'PAK'" class="subheading muted">
              Purchase Invoice Number
            </div>
            <div v-if="working != 'PAK'" class="heading">
              {{ detail.invoiceNumber }}
            </div>
          </v-flex>
          <div v-if="detail.invoiceNumber == 'NA'"></div>
          <div v-else>
            <v-flex
              xs4
              v-permissions="'view-purchase-invoice'"
              v-if="
                detail.invoicePdfUrl &&
                detail.isPurchaseInvoiceGenerated &&
                profile.workOrderCompleted &&
                working != 'PAK' &&
                working != 'OMAN' &&
                detail.assignmentStatus != -1
              "
            >
              <div
                class="subheading muted pl-2"
                style="text-align: center !important"
              >
                View Purchase Invoice
              </div>
              <div class="heading">
                <div>
                  <v-btn
                    v-if="detail.isPurchaseInvoiceDownloadAllowed"
                    :disabled="loading || viewOnly == 'view'"
                    outline
                    style="padding: 0 5px"
                    @click="
                      checkViewInvoice(
                        detail.invoicePdfUrl,
                        detail.isDownloadInvocie,
                        detail.isPurchaseInvoviceBackgroundProcess,
                        detail.invoiceNumber
                      )
                    "
                    color="orange"
                    ><v-icon style="margin: 0px !important" right dark
                      >download</v-icon
                    >
                    Download
                  </v-btn>
                  <v-btn
                    v-permissions="'pi-download-control'"
                    v-if="detail.isPurchaseInvoiceDownloadAllowed"
                    class="download-ass-btn"
                    outline
                    :loading="loading"
                    :disabled="loading"
                    title="hide"
                    color="orange"
                    @click="hideUnhidePI(false, detail._id)"
                  >
                    Hide
                  </v-btn>
                  <v-btn
                    v-permissions="'pi-download-control'"
                    v-if="!detail.isPurchaseInvoiceDownloadAllowed"
                    class="download-ass-btn"
                    outline
                    :loading="loading"
                    :disabled="loading"
                    title="hide"
                    color="orange"
                    @click="hideUnhidePI(true, detail._id)"
                  >
                    Unhide
                  </v-btn>
                </div>
              </div>
            </v-flex>

            <v-flex
              xs3
              v-if="
                !detail.invoicePdfUrl &&
                !detail.isPurchaseInvoiceGenerated &&
                working != 'PAK' &&
                working != 'OMAN' &&
                detail.assignmentStatus != -1
              "
            >
              <div class="subheading muted pr-2">View Purchase Invoice</div>
              <div class="heading pr-2">
                <div>
                  <v-btn
                    v-permissions="'pi-download-control'"
                    v-if="detail.isPurchaseInvoiceDownloadAllowed"
                    class="download-ass-btn"
                    outline
                    :loading="loading"
                    :disabled="loading"
                    title="hide"
                    color="orange"
                    @click="hideUnhidePI(false, detail._id)"
                  >
                    Hide
                  </v-btn>
                  <v-btn
                    v-permissions="'pi-download-control'"
                    v-if="!detail.isPurchaseInvoiceDownloadAllowed"
                    class="download-ass-btn"
                    outline
                    :loading="loading"
                    :disabled="loading"
                    title="unhide"
                    color="orange"
                    @click="hideUnhidePI(true, detail._id)"
                  >
                    Unhide
                  </v-btn>
                </div>
              </div>
            </v-flex>
          </div>
          <div v-if="detail.isPurchaseOrderInQueue">
            <div class="subheading muted">Purchase Voucher No:</div>
            Background process is ongoing
          </div>
          <v-flex
            xs4
            v-else-if="
              (detail.assignmentStatus == -1 && working != 'PAK') ||
              (detail.assignmentStatus == -1 && working != 'OMAN')
            "
          >
            <div class="subheading muted">Purchase Voucher No:</div>
            <div class="heading">Assignment is cancelled</div>
          </v-flex>
          <v-flex
            xs4
            v-else-if="
              (detail.assignmentStatus == 8 && working != 'PAK') ||
              (detail.assignmentStatus == 8 && working != 'OMAN')
            "
          >
          </v-flex>

          <v-flex
            xs4
            v-else-if="
              ((detail.assignmentStatus != 1 ||
                detail.assignmentStatus != -1) &&
                !profile.workOrderCompleted &&
                working != 'PAK') ||
              ((detail.assignmentStatus != 1 ||
                detail.assignmentStatus != -1) &&
                !profile.workOrderCompleted &&
                working != 'OMAN')
            "
          >
            <div class="subheading muted">Purchase Voucher No:</div>
            <div class="heading">Work order is not generated</div>
          </v-flex>

          <v-flex
            xs6
            v-else-if="
              !detail.purchaseOrderCreated &&
              detail.errorWhileRealbooks &&
              detail.driverId &&
              working != 'PAK' &&
              working != 'OMAN' &&
              (detail.assignmentStatus != 1 || detail.assignmentStatus != -1)
            "
          >
            <div class="subheading muted">Purchase Voucher No:</div>
            <div v-if="detail.isPurchaseOrderInQueue">
              Background process is ongoing
            </div>
            <div v-else-if="!detail.isPurchaseOrderInQueue">
              <span
                v-if="
                  detail.isPurchaseOrderBackgroundProcess &&
                  detail.errorWhileRealbooks
                "
                >Data inappropriate, kindly update data</span
              >
              <v-btn
                class="track-ass-btn"
                flat
                v-if="
                  !detail.isPurchaseOrderBackgroundProcess &&
                  detail.errorWhileRealbooks
                "
                prepend-icon
                color="white darken-1"
                :disabled="profile.jobStatus == 10 || viewOnly == 'view'"
                @click="retryPO(detail.assignmentId)"
                :loading="poloading"
                :_id="detail.assignmentId"
                >Retry</v-btn
              >
            </div>
          </v-flex>

          <v-flex
            xs4
            v-else-if="
              !detail.purchaseOrderCreated &&
              !detail.errorWhileRealbooks &&
              detail.driverId &&
              working != 'OMAN' &&
              working != 'PAK' &&
              (detail.assignmentStatus != 1 || detail.assignmentStatus != -1)
            "
          >
            <div class="subheading muted">Purchase Voucher No:</div>
            <div v-if="detail.isPurchaseOrderInQueue">
              Background process is ongoing
            </div>
            <div v-else-if="!detail.isPurchaseOrderInQueue">
              <v-btn
                class="track-ass-btn"
                flat
                prepend-icon
                color="white darken-1"
                :disabled="profile.jobStatus == 10 || viewOnly == 'view'"
                @click="retryPO(detail.assignmentId)"
                :_id="detail.assignmentId"
                :loading="poloading"
                >Retry
              </v-btn>
            </div>
          </v-flex>
          <v-flex
            xs4
            v-else-if="
              (detail.voucherIdRealBooks &&
                working != 'PAK' &&
                (detail.assignmentStatus != 1 ||
                  detail.assignmentStatus != -1)) ||
              (detail.voucherIdRealBooks &&
                working != 'OMAN' &&
                (detail.assignmentStatus != 1 || detail.assignmentStatus != -1))
            "
          >
            <div class="subheading muted">Purchase Voucher No:</div>
            <div class="heading">{{ detail.voucherIdRealBooks }}</div>
          </v-flex>

          <v-flex
            xs4
            v-if="
              detail.isPurchaseInvoiceGenerated &&
              working != 'PAK' &&
              detail.assignmentStatus != -1
            "
            v-permissions="'view-sales-invoice'"
          >
            <div class="subheading muted">
              Sales Invoice (Assignment level) :
            </div>
            <div v-if="detail.salesInvoiceNumber == 'NA'">N.A</div>
            <div v-else>
              <div
                class="heading"
                v-if="
                  detail.salesInvoiceNumber && detail.isSalesInvoiceGenerated
                "
              >
                {{ detail.salesInvoiceNumber }}
                <div class="heading">
                  <v-btn
                    outline
                    style="padding: 0 5px"
                    @click="
                      checkViewInvoice2(
                        detail.salesInvoicePdfUrl,
                        detail.isDownloadInvocie,
                        detail.isSaleInvoviceBackgroundProcess,
                        detail.salesInvoiceNumber
                      )
                    "
                    color="orange"
                    ><v-icon style="margin: 0px !important" right dark
                      >download</v-icon
                    >
                    Download
                  </v-btn>
                </div>
              </div>
              <div v-else>N.A.</div>
            </div>
          </v-flex>
        </v-layout>
        <v-layout row class="py-4" style="text-align: initial">
          <v-flex xs2 v-if="detail.isReadyForFinalPayment">
            <div class="subheading muted">Driver Physical Doc Proof</div>
            <div class="heading">
              <button
                class="track-ass-btn blue-button"
                flat
                prepend-icon
                color="white darken-1"
                @click.stop="goToProof(detail._id)"
              >
                View Docs
              </button>
            </div>
          </v-flex>
          <v-flex xs2 v-if="detail.amountPaidToDriver">
            <div class="subheading muted">Payment Proof</div>
            <div class="heading" style="padding-top: 8px">
              <proof-of-delivery
                :assignId="detail._id"
                :type="'finalPayProof'"
              />
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-layout>
          <v-spacer />

          <div v-if="!detail.hasVasAttached">
            <v-btn
              v-permissions="'cancel-assignment-jobs'"
              color="red darken-2"
              class="white--text"
              flat
              :disabled="
                profile.jobStatus == 10 ||
                viewOnly == 'view' ||
                detail.assignmentStatus == -1
              "
              @click="checkCancel()"
              >Cancel Assignment</v-btn
            >

            <!-- <v-btn
              v-permissions="'cancel-assignment-jobs'"
              v-if="!detail.isGenerated && detail.assignmentStatus == 8"
              color="red darken-2"
              class="white--text"
              flat
              :disabled="profile.jobStatus == 10 || viewOnly == 'view'"
              @click="checkCancel()"
              >Cancel Assignment</v-btn
            > -->
          </div>

          <AddPhysicalDoc
            :assignDetails="detail"
            :section="'jobDetails'"
            :driverCreditDuration="
              detail.driverData ? detail.driverData.driverPaymentDuration : null
            "
            @ready-to-pay="refresh()"
          />
          <EditPhysicalDoc
            v-if="!detail.amountPaidToDriver"
            :assignDetails="detail"
            :section="'jobDetails'"
            :driverCreditDuration="
              detail.driverData ? detail.driverData.driverPaymentDuration : null
            "
            @ready-to-pay="refresh()"
          />

          <!-- <v-btn
            v-permissions="'complete-assignment-jobs'"
            v-if="status === 6"
            :loading="loading"
            color="orange darken-2"
            class="white--text"
            :disabled="profile.jobStatus == 10 || viewOnly == 'view'"
            @click.native="checkComplete()"
            >Complete Assignment</v-btn
          > -->
          <BidResponse
            :id="detail._id"
            :assignmentId="detail.assignmentId"
            :job-id="detail.jobId"
            :startDate="detail.startDate"
            v-if="jobType == 1"
            :disabled="profile.jobStatus == 10 || viewOnly == 'view'"
          />
        </v-layout>
      </v-card-actions>
      <v-layout>
        <v-flex xs6></v-flex>
        <v-flex xs6 style="padding-left: 180px"
          >* Note - Attach Milestone before assigning the driver</v-flex
        >
      </v-layout>
    </v-card>
    <v-snackbar
      :timeout="3000"
      bottom
      color="red darken-2"
      v-model="error"
      class="white--text"
      v-if="error"
      >{{ error }}</v-snackbar
    >
    <!--- select milestone -->
    <v-dialog
      v-model="dialog3"
      persistent
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar dark :color="options.color" dense flat>
          <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text v-show="!!message" class="pa-4">{{ message }}</v-card-text>

        <v-card-actions class="pt-0">
          <v-btn color="primary darken-1" @click.native="selectMilestone"
            >Yes</v-btn
          >
          <v-btn color="grey" text @click.native="cancel3">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--- milestone info -->
    <v-dialog
      v-model="dialog2"
      persistent
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar dark :color="options.color" dense flat>
          <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text v-show="!!message" class="pa-38"
          >{{ message }}
          <v-layout>
            <v-flex xs7>
              <span class="muted">Actual Milestones Date&Time </span>
              <v-menu
                ref="FilterDateBool"
                lazy
                v-model="FilterDateBool"
                :close-on-content-click="false"
                transition="scale-transition"
                full-width
                :nudge-right="40"
                min-width="290px"
                :return-value.sync="startDate"
              >
                <v-text-field
                  slot="activator"
                  required
                  v-model="startDate"
                  class="ft-15 pt-0"
                  prepend-icon="event"
                  readonly
                ></v-text-field>
                <v-date-picker
                  v-model="startDate"
                  class="minHeight"
                  :max="dateToday"
                  @change="$refs.FilterDateBool.save(startDate)"
                  no-title
                  scrollable
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs2 pl-2 style="position: relative; padding-top: 22px">
              <vue-timepicker
                class="fonttime pt-1 currencyTitle"
                :minute-interval="30"
                v-model="pickupTime"
                input-width="10em"
                :label="'Actual Milestone Date'"
                placeholder="Actual Milestone Time"
                close-on-complete
              ></vue-timepicker>
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-card-actions class="pt-0 pl-5">
          <v-btn
            color="primary darken-1"
            text
            :loading="loading"
            :disabled="profile.jobStatus == 10 || viewOnly == 'view' || loading"
            @click.native="
              updateMilestone(milestoneTrack.assId, currentMilestoneId)
            "
            >Yes
          </v-btn>
          <v-btn color="grey" text @click.native="cancel1">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="confirmationDialog.cancelAssignment"
      v-model="confirmationDialog.cancelAssignment"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title dark dense flat style="background-color: #ffc04c">
          <div class="headline white--text">Confirmation of cancellation</div>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-flex pt-2 pr-1>
              <div class="subheading muted caption">
                Provide cancellation reason
              </div>
              <v-select
                class="pt-0 currencyTitle"
                v-model="cancelType"
                :items="cancellationType"
                label="Select your option"
                persistent-hint
                :menu-props="{ offsetY: true }"
                return-object
                single-line
              ></v-select>
            </v-flex>
            <div
              v-if="cancelType.value == 'Other’s'"
              class="subheading muted caption"
            >
              Reason
            </div>
            <v-text-field
              class="pt-0 currencyTitle"
              v-if="cancelType.value == 'Other’s'"
              type="text"
              single-line
              label="Enter your reason"
              required
              v-model="cancellationReason"
              :value="cancellationReason"
            />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-layout>
            <v-btn
              color="primary darken-1"
              text
              @click.native="open3"
              :loading="cancelLoading"
              >Yes</v-btn
            >
            <v-btn color="grey" @click.native="closeCancelAssignment" text
              >Cancel</v-btn
            >
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="invoiceDialog"
      persistent
      :max-width="500"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar dark style="background-color: #fee9d0; color: black" flat>
          <v-toolbar-title>{{ headerVatPopup }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon
              class="heading grey--text text--darken-4"
              @click.prevent="invoiceDialog = false"
              :disabled="loading"
              >close</v-icon
            >
          </v-btn>
        </v-toolbar>
        <v-spacer class="spacerclass">
          <div style="text-align: center">
            <div class="pb-4">
              <img
                src="../../../assets/ConfirmationPi.svg"
                height="350"
                width="500"
                class="spMargin"
              />
            </div>

            <div>{{ messageVat }}</div>
          </div>

          <v-card-actions class="pt-2 pb-5" style="justify-content: center">
            <v-btn
              v-if="vatInfo"
              medium
              style="text-transform: none !important"
              color="red white--text "
              darken-1
              :disabled="loading"
              text
              @click="confirmationPiGenerate('Without VaT')"
              >No</v-btn
            >
            <v-btn
              v-if="!vatInfo"
              color="grey"
              @click="invoiceDialog = false"
              text
              >Cancel</v-btn
            >
            <v-btn
              v-if="!vatInfo"
              medium
              style="text-transform: none !important"
              color="green white--text "
              v-permissions="'generate-purchase-invoice'"
              darken-1
              @click="generateInvoiceNumber()"
              :disabled="loading"
              text
              >Yes</v-btn
            >
            <v-btn
              v-if="vatInfo"
              medium
              style="text-transform: none !important"
              color="green white--text "
              v-permissions="'generate-purchase-invoice'"
              darken-1
              @click="confirmationPiGenerate('With VaT')"
              :disabled="loading"
              text
              >Yes</v-btn
            >
          </v-card-actions>
        </v-spacer>
      </v-card>
    </v-dialog>
    <!--Cancellation-confirmation-popup---->
    <v-dialog
      v-model="dialog5"
      persistent
      :max-width="450"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar
          dark
          style="background-color: #fee9d0; color: black; padding-left: 100px"
          flat
        >
          <v-toolbar-title>{{ cancelTitle1 }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon
              class="heading grey--text text--darken-4"
              @click.prevent="
                dialog5 = false;
                cancelType = '';
                cancellationReason = null;
              "
              >close</v-icon
            >
          </v-btn>
        </v-toolbar>
        <v-spacer class="spacerclass">
          <v-card-text
            style="text-align: center"
            v-show="!!cancelMessage1"
            class="pa-4"
          >
            <div class="pb-4">
              <img
                src="../../../assets/Character-sitting.png"
                height="300"
                width="300"
              />
            </div>
            <div class="subheading">{{ cancelMessage1 }}</div>
            <div
              v-if="cancelType.value == 'Other’s'"
              class="subheading muted pt-2"
            >
              <span style="color: darkgrey">Cancellation reason:</span>
              <p style="word-break: break-all">
                {{ cancelType.value }} - {{ cancellationReason }}
              </p>
            </div>
            <div v-else class="subheading muted pt-2">
              <span style="color: darkgrey">Cancellation reason:</span>
              {{ cancelType.value }}
            </div>
          </v-card-text>

          <v-card-actions class="pt-2 pb-5" style="justify-content: center">
            <v-btn
              medium
              style="text-transform: none !important"
              color="orange white--text "
              darken-1
              :loading="cancelLoading"
              text
              @click.native="cancelAssignment"
              >DONE</v-btn
            >
          </v-card-actions>
        </v-spacer>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogPi"
      persistent
      :max-width="550"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar dark style="background-color: #fee9d0; color: black" flat>
          <v-toolbar-title> Final Confirmation</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon
              class="heading grey--text text--darken-4"
              @click.prevent="dialogPi = false"
              >close</v-icon
            >
          </v-btn>
        </v-toolbar>
        <v-spacer class="spacerclass">
          <v-card-text class="pa-4">
            {{ ConfirmMessageVat }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="vatInfo"
              medium
              style="text-transform: none !important"
              color="red white--text "
              darken-1
              :disabled="loading"
              text
              @click="dialogPi = false"
              >No</v-btn
            >
            <v-btn
              v-if="!FinalvatApply"
              medium
              style="text-transform: none !important"
              color="green white--text "
              v-permissions="'generate-purchase-invoice'"
              darken-1
              @click="generateInvoiceNumber(), (vatAmount = 0)"
              :disabled="loading"
              text
              >Yes</v-btn
            >

            <v-btn
              v-if="FinalvatApply"
              medium
              style="text-transform: none !important"
              color="green white--text "
              v-permissions="'generate-purchase-invoice'"
              darken-1
              @click="generateInvoiceNumber()"
              :disabled="loading"
              text
              >Yes</v-btn
            >
          </v-card-actions>
        </v-spacer>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogMilestoneUnmark"
      persistent
      :max-width="700"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar dark style="background-color: #fee9d0; color: black" flat>
          <v-toolbar-title> {{ unmarkMilestonePopupHeading }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon
              class="heading grey--text text--darken-4"
              @click.prevent="closedUnmarkedMilestonePopup()"
              >close</v-icon
            >
          </v-btn>
        </v-toolbar>
        <v-spacer class="spacerclass">
          <v-card-text class="pa-4">
            {{ unmarkMilestoneMessage }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              medium
              style="text-transform: none !important"
              color="red white--text "
              darken-1
              :disabled="loading"
              text
              @click="closedUnmarkedMilestonePopup()"
              >No</v-btn
            >
            <v-btn
              medium
              style="text-transform: none !important"
              color="green white--text "
              darken-1
              @click="confirmedUnmarkedMilestone()"
              :disabled="loading"
              text
              >Yes</v-btn
            >
          </v-card-actions>
        </v-spacer>
      </v-card>
    </v-dialog>

    <!---Asset-ERROR------->
    <v-dialog
      v-model="dialog6"
      persistent
      :max-width="450"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar
          dark
          style="background-color: #fee9d0; color: black; padding-left: 130px"
          flat
        >
          <v-toolbar-title>{{ errorTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon @click.prevent="closeAssetPopup()">close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-spacer class="spacerclass">
          <v-card-text style="text-align: center" class="pa-4">
            <div class="pb-4">
              <img
                src="../../../assets/Character-jumping.svg"
                height="300"
                width="300"
              />
            </div>
            <div class="subheading">{{ errorMessage }}</div>
          </v-card-text>

          <v-card-actions class="pt-2 pb-5" style="justify-content: center">
            <v-btn
              medium
              style="text-transform: none !important"
              color="orange white--text "
              darken-1
              @click="closeAssetPopup()"
              :loading="cancelLoading"
              text
              >DONE</v-btn
            >
          </v-card-actions>
        </v-spacer>
      </v-card>
    </v-dialog>
    <!--Driver-Error-Popup-->
    <v-dialog
      v-model="dialog7"
      persistent
      :max-width="450"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar
          dark
          style="background-color: #fee9d0; color: black; padding-left: 130px"
          flat
        >
          <v-toolbar-title>{{ errorTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon
              class="heading grey--text text--darken-4"
              @click.prevent="closeOnBoradingPopup()"
              >close</v-icon
            >
          </v-btn>
        </v-toolbar>
        <v-spacer class="spacerclass">
          <v-card-text style="text-align: center" class="pa-4">
            <div class="pb-4">
              <img
                src="../../../assets/Illustrations-groovy.svg"
                height="300"
                width="300"
              />
            </div>
            <div class="subheading">{{ errorMessage }}</div>
          </v-card-text>

          <v-card-actions class="pt-2 pb-5" style="justify-content: center">
            <v-btn
              medium
              style="text-transform: none !important"
              color="orange white--text "
              darken-1
              @click="closeOnBoradingPopup()"
              :loading="cancelLoading"
              text
              >DONE</v-btn
            >
          </v-card-actions>
        </v-spacer>
      </v-card>
    </v-dialog>

    <!-- ADD COMMENT DIALOG -->
    <v-dialog v-model="addCommentdialog" persistent max-width="40%">
      <v-form ref="form" onsubmit="return false;">
        <v-card>
          <v-card-title>
            <span class="headline">Attach Comment with the Milestone</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <div class="subheading muted">Type your comment here</div>
                  <div class="heading">
                    <v-textarea
                      autocomplete="comments"
                      single-line
                      class="pt-0 currencyTitle"
                      v-model="comments"
                    ></v-textarea>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
            <small class="success" v-show="this.addCommentRes">{{
              this.addCommentRes
            }}</small>
            <small class="error" v-show="this.addCommentx.error">{{
              this.addCommentx.error
            }}</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" flat @click="closeAddComment"
              >Close</v-btn
            >
            <v-btn color="blue darken-1" flat @click="sendComment"
              >Submit</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <payment-dialog
      :show="confirmationDialog.makePayment"
      :onClose="() => (confirmationDialog.makePayment = false)"
      :assignmentId="detail.assignmentId"
      :profile="profile"
      :onConfirm="makePayment"
      :paymentAmount="detail.paymentAmount"
      :paymentCurrency="detail.paymentCurrency"
    />
    <success-dialog
      content="Payment successful"
      :show="showPaymentSuccess"
      :onclose="() => (showPaymentSuccess = false)"
    />
    <success-dialog
      :content="x.message"
      :show="x.success"
      :onclose="closeMessage"
    />
    <v-dialog
      v-model="dialog"
      persistent
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar dark :color="options.color" dense flat>
          <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text v-show="!!message" class="pa-4">
          {{ message }}
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-btn color="primary darken-1" text @click.native="agree">Yes</v-btn>
          <v-btn color="grey" text @click.native="cancel">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import JobsProfileDriverAssign from "@/components/Jobs/AssignDriver/JobsProfileDriverAssign";
import jobProfileDriverAssignAir from "@/components/Jobs/AssignDriver/jobProfileDriverAssignAir";
import GenerateAdvance from "@/components/Pop-ups/GenerateAdvance";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import ProofOfDelivery from "@/components/Pop-ups/ProofOfDelivery";
import jobProfileDriverAssignSea from "@/components/Jobs/AssignDriver/jobProfileDriverAssignSea";

import {
  TruckTypes,
  PackingTypes,
  NatureOfCargos,
  JobTypes,
  SeaTypes,
  AirTypes,
} from "../../../constants/constants";
import ErrorBox from "@/components/Common/ErrorBox";
import { EventBus } from "../../../event-bus.js";
import BidResponse from "@/components/Pop-ups/BidResponse";
import moment from "moment";
import ConfirmationDialog from "@/components/Common/ConfirmationDialog";
import PaymentDialog from "@/components/Pop-ups/PaymentDialog";
import SuccessDialog from "@/components/Common/SuccessDialog";
import AddComment from "@/components/Pop-ups/AddComment";
import AddPhysicalDoc from "@/components/Pop-ups/AddPhysicalDoc";
import EditPhysicalDoc from "@/components//Pop-ups/EditPhysicalDoc";
import ViewComment from "@/components/Pop-ups/ViewComment";
import changeMilestone from "../JobDetails/changeMilestone.vue";
import { StorageKeys, CancellationType } from "../../../constants/constants";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.min.css";
import downloadMixin from "@/mixins/downloadMixin";
import {
  generatePurchaseInvoice,
  driverDocPdf,
  generatePurchaseOrder,
  updateAssignments,
  updateAssignmentsMilestone,
  trackingStatus,
  milestoneList,
  editComment,
  cancelAssignments,
  removeDriver,
  completeAssignments,
  jobsProfileAssignmentList,
} from "../../../constants/api-urls";
export default {
  mixins: [downloadMixin],
  created() {
    this.currentTime();
    this.milestoneItems = this.$props.milestoneItemsList;
    console.log(this.milestoneTrack);
    this.getTrackingStatus();
    this.cancellationType = CancellationType;
    this.$eventBus.$on("open-assign-driver", () => {
      this.checkAssign();
      this.$eventBus.$off("open-assign-driver");
    });
    this.$eventBus.$on("refresh-milstoneChange", () => {
      setTimeout(() => {
        this.getTrackingStatus();
      }, 2000);
      this.status = this.detail.assignmentStatus;
    });
    this.$eventBus.$on("refresh-job", () => {
      console.log(
        "refreshtry",
        this.detail.assignmentStatus,
        this.$props.detail.assignmentStatus
      );
      this.status = this.$props.detail.assignmentStatus;
    });

    this.$eventBus.$on("open-unassign-driver", () => {
      this.checkUnassign();
    });
    this.$eventBus.$on("open-driver-form", () => {
      this.driverpdfDownload();
    });
    this.$eventBus.$on("open-driver-onboarding-form", () => {
      this.driverErrorOpen();
    });
    this.$eventBus.$on(
      "job-driver-assign-success",
      ({ driverId, driverName, assignmentId }) => {
        this.getTrackingStatus();
        this.$emit("updateJobs");

        if (assignmentId !== this.detail.assignmentId) {
          return;
        }

        this.driverId = driverId;
        this.driverName = driverName;
        this.driverAssignModal = false;

        this.$eventBus.$emit("driver-assignment", {
          driverId,
          driverName,
          assignmentId: this.detail.assignmentId,
        });
      }
    );

    this.$eventBus.$on("sales-invoice-assignment", () => {
      setTimeout(() => {
        this.$eventBus.$emit("refresh-job");
      }, 5000);
      console.log("socket coming------");
    });

    this.$eventBus.$on("VASPAK", (data) => {
      console.log("socket receved");
      this.$eventBus.$emit("refresh-job");
    });

    delete this.axios.defaults.headers.common["token"];
    let token = localStorage.getItem(StorageKeys.SessionKey);
    let config = {
      headers: {
        authorization: `bearer ${token}`,
      },
    };
    this.t = config.headers.authorization;
  },

  beforeDestroy() {
    this.$eventBus.$off("job-driver-assign-success");
    this.$eventBus.$off("open-asset-form");
    this.$eventBus.$off("open-driver-onboarding-form");
    this.$eventBus.$off("open-driver-form");
    this.$eventBus.$off("open-unassign-driver");
    this.$eventBus.$off("open-assign-driver");
  },
  props: [
    "working",
    "assetForm",
    "assignments",
    "milestoneItemsList",
    "statusData",
    "jobId",
    "assetId",
    "job",
    "loader",
    "detail",
    "steps",
    "milestoneList",
    "milestoneItemsList",
    "customer",
    "profile",
    "jobType",
    "tabOrder",
    "viewOnly",
  ],
  data() {
    return {
      dateToday: moment(new Date()).format("YYYY-MM-DD"),
      pickupTime: "",
      confirmation: "",
      valid: true,
      FilterDateBool: false,
      startDate: moment(new Date()).format("YYYY-MM-DD"),
      options: {
        color: "#ffc04c",
        width: 450,
        zIndex: 200,
      },
      invoiceDialog: false,
      dialogPi: false,
      workingCountry: "",
      dialogMilestoneUnmark: false,
      unmarkMilestoneMessage: "",
      unmarkMilestonePopupHeading: "",
      selectedUnmarkCheckpointId: "",
      selectedUnmarkMilestoneId: "",

      operationName: "",
      cancelTitle1: null,
      cancelMessage1: null,
      dialog2: false,
      test1: false,
      cancellationType: [],
      cancelType: "",
      dialog5: false,
      dialog6: false,
      dialog7: false,
      errorMessage: null,
      messageVat: null,
      ConfirmMessageVat: null,
      headerVatPopup: null,
      errorTitle: null,

      options: {
        color: "#ffc04c",
        width: 450,
        zIndex: 1,
      },
      addCommentdialog: false,
      vatAmount: null,
      addCommentRes: "",
      isAddCommentEdit: false,
      addCommentx: {
        error: "",
      },
      addCommentAssId: "",
      addCommentCheckListId: "",
      comments: "",
      readOnly: true,
      natureOfCargos: NatureOfCargos,
      truckTypes: TruckTypes,
      airTypes: AirTypes,
      seaTypes: SeaTypes,
      packingTypes: PackingTypes,
      status: this.detail.assignmentStatus,
      loading: false,
      showPaymentSuccess: false,
      x: {
        message: "",
        loading: false,
        success: false,
      },
      updateInfo: {
        sourceLocation: this.detail.sourceLocation,
        sourceCity: this.detail.sourceCity,
        sourceCountry: this.detail.sourceCountry,
        destinationLocation: this.detail.destinationLocation,
        destinationCity: this.detail.destinationCity,
        destinationCountry: this.detail.destinationCountry,
      },
      milestone: {},
      milestoneItems: [],
      currentMilestoneId: "",
      currentMilestone: 0,
      milestoneTrack: {
        milestone: [],
        assId: null,
      },
      confirmationDialog: {
        cancelAssignment: false,
        makePayment: false,
        completeAssignment: false,
        removeDriverAssignment: false,
        removeTransporterAssignment: false,
      },
      driverAssignModal: false,
      driverId: this.detail.driverId,
      driverName: this.getDriverName(),

      error: null,
      loading: false,
      unassignLoading: false,
      cancelLoading: false,
      cancellationReason: null,
      milestoneArray: [],
      poloading: false,
      vatInfo: false,
      FinalvatApply: false,

      title: null,
      message: null,
      dialog: false,
      dialog3: false,
      statusComments: "",
      options: {
        color: "#ffc04c",
        width: 460,
        zIndex: 200,
      },
    };
  },
  components: {
    BidResponse,
    AddPhysicalDoc,
    EditPhysicalDoc,
    GenerateAdvance,
    JobsProfileDriverAssign,
    jobProfileDriverAssignAir,
    changeMilestone,
    jobProfileDriverAssignSea,
    ErrorBox,
    ConfirmationDialog,
    AddComment,
    ViewComment,
    PaymentDialog,
    SuccessDialog,
    VueTimepicker,
    ProofOfDelivery,
  },

  watch: {
    milestoneList(val) {
      this.milestoneItems = val;
    },
  },
  methods: {
    goToProof(_id) {
      this.$router.push(`/documents/${_id}?type=Driver_Proof`);
    },
    refresh() {
      this.$eventBus.$emit("refresh-job");
    },
    hideUnhidePI(val, id) {
      this.loading = true;
      console.log(val, id);

      let url = "/assignments/purchase-invoice-switch";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        assignmentId: id.toString(),
        isPurchaseInvoiceDownloadAllowed: val,
      };

      this.axios.patch(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.refresh();
          this.loading = false;
        },
        (err) => {
          this.loading = false;
        }
      );
    },
    checkLastMilestoneStatus(n) {
      console.log(this.milestoneArray.length, "--length", n);
      if (this.milestoneArray.length === n + 1) {
        return true;
      } else {
        if (!this.milestoneArray[n + 1].isChecked) {
          return true;
        } else {
          return false;
        }
      }
    },
    checkTime() {
      let re = /^\d{1,2}:\d{2}([ap]m)?$/;
      var time = this.pickupTime;

      if (time != "" && !time.match(re)) {
        this.error = "Please select the valid pickup time before moving ahead!";
        time.focus();
        return false;
      }
    },
    currentTime() {
      var currentdate = new Date();
      this.pickupTime = (
        currentdate.getHours() +
        ":" +
        (currentdate.getMinutes() <= 9
          ? "0" + currentdate.getMinutes()
          : currentdate.getMinutes())
      ).toString();
    },
    closeAssetPopup() {
      this.dialog6 = false;
      this.$emit("driverAssetPopUpClosed", false);
    },
    unmarkMilestone(assignId, milestoneID, checkPointId, milestoneName) {
      console.log("true", assignId, milestoneID, checkPointId);

      this.unmarkMilestonePopupHeading = `Uncheck (${milestoneName}) Checkpoint For Assig. Id-${assignId}`;
      this.unmarkMilestoneMessage = `Are you sure you want to uncheck this checkpoint?  `;
      (this.selectedUnmarkCheckpointId = checkPointId),
        (this.selectedUnmarkMilestoneId = milestoneID),
        (this.dialogMilestoneUnmark = true);
    },

    confirmedUnmarkedMilestone() {
      let url = "/assignments/uncheck-milestone";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        assignmentId: this.detail._id.toString(),
        milestoneId: this.selectedUnmarkMilestoneId,
        checkPointId: this.selectedUnmarkCheckpointId,
      };

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.getTrackingStatus();
          this.dialogMilestoneUnmark = false;

          this.unmarkMilestonePopupHeading = "";
          this.unmarkMilestoneMessage = "";
          (this.selectedUnmarkCheckpointId = ""),
            (this.selectedUnmarkMilestoneId = "");

          this.$eventBus.$emit("refresh-job");
        },
        (error) => {
          this.dialogMilestoneUnmark = false;

          this.unmarkMilestonePopupHeading = "";
          this.unmarkMilestoneMessage = "";
          (this.selectedUnmarkCheckpointId = ""),
            (this.selectedUnmarkMilestoneId = "");
          this.error = error.response.data.message;
        }
      );
    },
    closedUnmarkedMilestonePopup() {
      this.dialogMilestoneUnmark = false;

      this.unmarkMilestonePopupHeading = "";
      this.unmarkMilestoneMessage = "";
      (this.selectedUnmarkCheckpointId = ""),
        (this.selectedUnmarkMilestoneId = "");
    },
    confirmationPiGenerate(data) {
      console.log(data);
      if (data == "With VaT") {
        console.log("true");
        this.ConfirmMessageVat = "Are you sure you want to apply VAT?";
        this.dialogPi = true;
        this.FinalvatApply = true;
      } else {
        console.log("false");
        this.FinalvatApply = false;
        this.ConfirmMessageVat = "Are you sure you don't want to apply VAT?";
        this.dialogPi = true;
      }
    },

    closeOnBoradingPopup() {
      this.dialog7 = false;
      this.$emit("driverFormPopUpClosed", false);
    },
    closeUnassignPopup() {
      this.$emit("driverUnAssignPopUpClosed", false);
      this.confirmationDialog.removeDriverAssignment = false;
    },
    closeUnassignTransporterPopup() {
      this.$emit("tranporterUnAssignPopUpClosed", false);
      this.confirmationDialog.removeTransporterAssignment = false;
    },

    async checkViewInvoice(
      url,
      isDownloadInvocie,
      isPurchaseInvoviceBackgroundProcess,
      invoiceNumber
    ) {
      this.operationName = "view-purchase-invoice";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        if (!isDownloadInvocie || isPurchaseInvoviceBackgroundProcess) {
          this.loading = false;
          this.error =
            "Purchase Invoice can't be downloaded, background process is ongoing ";
          return;
        }
        /* Mixin function*/
        this.forceDownload(url, "Purchase Invoice" + " " + invoiceNumber);
      } else {
        return;
      }
    },
    async checkViewInvoice2(
      url,
      isDownloadInvocie,
      isPurchaseInvoviceBackgroundProcess,
      invoiceNumber
    ) {
      this.operationName = "view-sales-invoice";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        if (isPurchaseInvoviceBackgroundProcess) {
          this.loading = false;
          this.error =
            "Purchase Invoice can't be downloaded, background process is ongoing ";
          return;
        }
        /* Mixin function*/
        this.forceDownload(url, "Sales Invoice" + " " + invoiceNumber);
      } else {
        return;
      }
    },
    async generateInvoiceNumber() {
      // this.dialogPi = true;
      this.loading = true;
      this.operationName = "generate-purchase-invoice";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.loading = true;
        let url = "/dmsAdmin/generatePurchaseInvoice";
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        let body = { assignmentId: this.detail.assignmentId.toString() };
        if (this.vatAmount > 0) {
          body.vatAmount = this.vatAmount;
        }
        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            this.items = response.data.data;
            this.loading = false;

            this.x.success = true;
            this.invoiceDialog = false;
            this.dialogPi = false;

            this.$eventBus.$emit("refresh-job");
          },
          (error) => {
            this.loading = false;
            this.invoiceDialog = false;
            this.dialogPi = false;

            this.error = error.response.data.message;
          }
        );
      } else {
        this.loading = false;
        return;
      }
    },
    async checkCancel() {
      this.operationName = "cancel-assignment-jobs";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.confirmationDialog.cancelAssignment = true;
      } else {
        return;
      }
    },
    async checkUnassign() {
      this.operationName = "unassign-driver-jobs";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.confirmationDialog.removeDriverAssignment = true;
      } else {
        return;
      }
    },
    async checkUnassignTransporter() {
      this.operationName = "unassign-driver-jobs";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.confirmationDialog.removeTransporterAssignment = true;
      } else {
        return;
      }
    },
    async checkAssign() {
      this.operationName = "assign-driver-jobs";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.loading = false;
        this.checkCustomer();
      } else {
        return;
      }
    },
    async checkComplete() {
      this.operationName = "complete-assignment-jobs";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.completeAssignment();
      } else {
        return;
      }
    },

    getText(arr = [], val) {
      let finalEl = null;

      finalEl = arr.find((el) => el.value === val.toString());

      return finalEl ? finalEl.text : "NA";
    },
    errorOpen() {
      if (!this.$props.assetId) {
        this.dialog6 = true;
        this.errorTitle = "Error Message";
        this.errorMessage = "Please assign IMEI on the assignment !";
        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      } else {
        this.$emit("driverAssetPopUpClosed", false);
        let routeData = this.$router.resolve(
          `/asset/${this.detail.assignmentId}/asset-form`
        );
        window.open(routeData.href, "_blank");
      }
    },
    driverErrorOpen() {
      if (!navigator.onLine) {
        this.loading = false;
        this.error = "Please check your internet connection";
        setTimeout(() => {
          this.x.error = "";
        }, 3000);
        return;
      }
      if (this.status < 2) {
        this.dialog7 = true;
        this.errorTitle = "Error Message";
        this.errorMessage = "Please assign driver on the assignment !";
        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      } else {
        this.$emit("driverFormPopUpClosed", false);
        let routeData = this.$router.resolve(
          `/driver/${this.detail.driverData.driverId}/driver-form`
        );
        window.open(routeData.href, "_blank");
      }
    },
    openPiPopup(id) {
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      this.t = config.headers.authorization;
      let body = {
        assignmentId: id,
      };
      this.axios
        .post(`${this.constants.apiUrl}/dmsAdmin/get-vat-details`, body, config)
        .then((response) => {
          console.log(response.data.data);
          if (response.data.data.amount > 0) {
            this.vatInfo = true;
            this.invoiceDialog = true;
            this.headerVatPopup = "Generate Purchase Invoice With VAT";
            this.messageVat = ` Do you want  to generate Purchase Invoice with  VAT ${
              response.data.data.vatPercent
            } % (${response.data.data.amount.toFixed(2)} ${
              response.data.data.currency
            }) ?`;
            this.vatAmount = response.data.data.amount;
          } else {
            this.invoiceDialog = true;
            this.vatInfo = false;

            this.headerVatPopup = "Generate Purchase Invoice";
            this.messageVat = ` Do you want to generate Purchase Invoice ?`;
          }
        });

      this.cancelloading = false;
      this.loading = false;

      // console.log(id)

      //  this.cancelloading = false;  this.loading = false;
    },

    driverpdfDownload() {
      this.$eventBus.$emit("on-load");
      let url = "/dmsAdmin/driverDocPdf";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = { driverId: this.detail.driverData.driverId };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.loading = false;
          this.$eventBus.$emit("off-load");
          const a = document.createElement("a");
          a.style.display = "none";
          document.body.appendChild(a);
          this.$emit("driverPdfPopUpClosed", false);
          // Set the HREF to a Blob representation of the data to be downloaded
          a.href = response.data.data;
          a.download = "documents" + this.detail.driverData.driverId;
          a.target = "_blank";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(a.href);
          document.body.removeChild(a);
        },
        (error) => {
          this.loading = false;
          this.$eventBus.$emit("off-load");
          this.error = error.response.data.message;
        }
      );
    },
    open3(val, index) {
      if (!navigator.onLine) {
        this.loading = false;
        this.error = "Please check your internet connection";
        setTimeout(() => {
          this.x.error = "";
        }, 3000);
        return;
      }
      if (!this.cancelType) {
        this.error = "Please select cancellation reason before moving ahead";
        setTimeout(() => {
          this.x.error = "";
        }, 3000);
        return false;
      }
      if (this.cancelType.value == "Other’s") {
        if (!this.cancellationReason) {
          this.error = "Please enter cancellation reason before moving ahead";
          setTimeout(() => {
            this.x.error = "";
          }, 3000);
          return false;
        }
      }
      this.confirmationDialog.cancelAssignment = false;
      this.dialog5 = true;
      this.cancelTitle1 = "Cancellation Message";
      this.cancelMessage1 = "Your job has been cancelled!";
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    cancel3() {
      this.resolve(false);
      this.dialog5 = false;
    },
    test() {
      ut = milestone.milestoneId;
    },
    getTime(time) {
      return moment.unix(time).format("DD/MM/YYYY");
    },
    getTimes(date) {
      return moment(date).format("ll LT");
    },
    getDate(date) {
      return moment(date).format("ll");
    },
    retryPO(assignId) {
      this.poloading = true;
      let url = "/dmsAdmin/generatePurchaseOrder";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = { assignId: assignId.toString() };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.poloading = false;
          this.$eventBus.$emit("update-po");
          this.$eventBus.$emit("refresh-job");
          setTimeout(() => (this.dialog = false), 100);
        },
        (error) => {
          this.poloading = false;
          this.error = error.response.data.message;
        }
      );
    },
    openMilestone() {
      this.dialog3 = true;
      this.title = "Are you sure?";
      this.message = "Click yes to select milestone , cancel to go back";
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.loading = true;
        this.reject = reject;
      });
    },
    selectMilestone() {
      let url = "/dmsAdmin/updateAssignMents";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        assId: this.detail.assignmentId.toString(),
        milestoneId: this.milestone,
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.dialog3 = false;
          this.$eventBus.$emit("refresh-job");
          this.getTrackingStatus();
        },
        (error) => {
          this.error = error.response.data.message;
        }
      );
    },
    cancel3() {
      this.milestone = "";
      this.resolve(false);
      this.dialog3 = false;
    },
    openInfoMilestone(mileStoneId, n) {
      this.loading = false;

      if (this.detail.assignmentStatus === 1) {
        this.error = "Please assign the driver";
        return;
      }
      this.currentMilestoneId = mileStoneId;

      this.dialog2 = true;
      this.currentTime();
      this.title = "Are you sure? ";
      this.message = "Click yes update milestone , cancel to go back";
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    updateMilestone(assId, checkListId) {
      if (this.loading) {
        return;
      } else {
        if (this.startDate) {
          if (!this.pickupTime) {
            this.error = "Please select actual milestone time!";
            return;
          } else {
            this.checkTime();

            let currentDate = moment(new Date()).format("YYYY-MM-DD, HH:mm");
            let selectedDateTime = this.startDate + "," + this.pickupTime;

            if (
              new Date(currentDate).getTime() <
              new Date(selectedDateTime).getTime()
            ) {
              this.loading = false;
              this.error =
                "Please select the past pickup time and date before moving ahead!";
              return false;
            }
          }
        }

        this.loading = true;
        this.addCommentAssId = assId;
        this.addCommentCheckListId = checkListId;
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        let url = "/dmsAdmin/updateAssignMilestone";
        let body = {
          assId: this.addCommentAssId,
          checkListId: this.addCommentCheckListId,
          comments: "",
        };
        if (this.startDate && this.pickupTime) {
          body.updatedAt = moment.utc(
            new Date(this.startDate + "," + this.pickupTime)
          );
        }
        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            this.dialog2 = false;

            this.$eventBus.$emit("milestoneChecked-completed");

            if (
              this.milestoneTrack.milestone.length ==
              this.detail.completedCheckList.length
            ) {
              this.status = 6;
            }
            if (
              this.milestoneTrack.milestone.length ==
              this.detail.completedCheckList.length + 1
            ) {
              this.status = 6;
            } else {
              this.status = 5;
            }
            this.loading = false;
            this.addCommentCheckListId = "";
            this.addCommentAssId = "";
            this.comments = "";
            this.addCommentdialog = false;
            this.isAddCommentEdit = false;
            this.milestoneItems = response.data.data;
            this.componentKey += 1;
            this.getTrackingStatus();
            setTimeout(() => {
              this.res = "";
              this.dialog2 = false;
              this.startDate = moment(new Date()).format("YYYY-MM-DD");
            }, 1000);
            setTimeout(() => {
              this.startDate = moment(new Date()).format("YYYY-MM-DD");
              this.currentTime();
            }, 2000);
          },
          (error) => {
            this.loading = false;
            this.addCommentx.error = "Unable to update milestone.";
            this.error = error.response.data.message;
          }
        );
      }
    },
    cancel1() {
      this.startDate = moment(new Date()).format("YYYY-MM-DD");
      this.currentTime();
      this.FilterDateBool = false;
      this.resolve(false);
      this.dialog2 = false;
      this.loading = false;
    },

    open() {
      this.dialog = true;
      this.title = "Customer's Documents";
      this.message =
        "Customer's documentation is not complete. Are you sure you want to proceed?";
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.driverAssignModal = true;
      this.dialog = false;
    },
    cancel() {
      this.$emit("driverAssignPopUpClosed", false);
      this.resolve(false);
      this.dialog = false;
    },
    checkCustomer() {
      if (
        this.customer.passPort &&
        this.customer.visa &&
        this.customer.emiratesId &&
        this.customer.vatCertificate &&
        this.customer.tradeLicense
      ) {
        "/job/assign-driver" + "?user=" + localStorage.getItem("user");
        this.driverAssignModal = true;
      } else {
        this.open();
      }
    },
    closeMessage() {
      this.x.success = false;
      this.componentKey += 1;
      this.getTrackingStatus();
    },
    getTrackingStatus() {
      this.$eventBus.$emit("on-load");
      let url = "/dmsAdmin/trackingStatus";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let assignmentId = this.detail.assignmentId.toString();
      let body = {
        assId: assignmentId,
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.$eventBus.$emit("off-load");
          this.milestoneArray = response.data.data;
          this.milestoneTrack.milestone = response.data.data;

          for (
            let index = 0;
            index < this.milestoneTrack.milestone.length;
            index++
          ) {
            if (!this.milestoneTrack.milestone[index].isChecked) {
              this.currentMilestone = index;
              break;
            }
          }
          this.milestoneTrack.assId = assignmentId;
        },
        (error) => {
          this.$eventBus.$emit("off-load");
          this.error = "Failed to Fetch Milestone Status";
        }
      );
    },
    getMilestones() {
      let url = "/dmsAdmin/milestoneList";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        workingCountry: [this.$props.working],
        assignDriver: "true",
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.milestoneItems = response.data.data;
        },
        (error) => {
          this.error = "Failed to Fetch Milestone Status";
        }
      );
    },
    closeAddComment() {
      this.isAddCommentEdit = false;
      this.comments = "";
      this.addCommentdialog = false;
      this.addCommentAssId = "";
      this.addCommentCheckListId = "";
    },
    editMilestoneComment(assId, checkListId, index) {
      this.isAddCommentEdit = true;
      this.comments = this.milestoneTrack.milestone[index].comments;
      this.addCommentdialog = true;
      this.addCommentAssId = assId;
      this.addCommentCheckListId = checkListId;
    },
    sendComment() {
      if (this.loading) {
        return;
      } else {
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };

        this.loading = true;
        let url = editComment;
        let body = {
          assignmentId: this.addCommentAssId,
          milestoneId: this.addCommentCheckListId,
        };

        if (this.comments.trim()) {
          body.comments = this.comments.trim();
        } else {
          this.addCommentx.error =
            "Please provide a comment before moving ahead.";
          this.loading = false;
          return false;
        }
        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            this.loading = false;
            this.addCommentCheckListId = "";
            this.addCommentAssId = "";
            this.comments = "";
            this.addCommentdialog = false;
            this.isAddCommentEdit = false;
            this.milestoneItems = response.data.data;
            this.x.success = "Milestone Updated";
            this.componentKey += 1;
            this.getTrackingStatus();
            setTimeout(() => {
              this.res = "";
              this.dialog = false;
            }, 3000);
          },
          (error) => {
            this.loading = true;
            this.addCommentx.error = "Unable to update milestone.";
          }
        );
      }
    },

    getDriverName() {
      return this.detail.driverFirstName
        ? this.detail.driverFirstName + " " + this.detail.driverLastName
        : "NA";
    },
    getStatus(status) {
      switch (status) {
        case 1:
          return "Driver Not Assigned";
        case 2:
          return "Driver Assigned";
        case 3:
          return "Payment Pending";
        case 4:
          return "Payment Done";
        case 5:
          return "In-Transit";
        case 6:
          return "Shipment Offloaded";

        case -1:
          return "Cancelled";
        default:
          return "Unknown";
      }
    },
    getText(arr = [], val, type) {
      let finalEl = null;
      if (type === "trucktype")
        finalEl = arr.find((el) => el.value === val.toString());
      else {
        finalEl = arr.find((el) => el.value === val);
      }
      return finalEl ? finalEl.text : "NA";
    },
    getLocation(...arr) {
      return arr
        ? arr
            .filter((val) => val !== undefined)
            .reduce((prev, curr) => prev + ", " + curr)
        : "NA";
    },
    cancelAssignment() {
      this.cancelLoading = true;
      let url = cancelAssignments;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      const data = {
        assignmentId: this.detail.assignmentId.toString(),
      };
      if (this.cancelType.value) {
        if (this.cancelType.value == "Other’s") {
          data.cancellationReason =
            this.cancelType.value + " - " + this.cancellationReason;
        } else {
          data.cancellationReason = this.cancelType.value;
        }
      }

      this.axios.post(this.constants.apiUrl + url, data, config).then(
        (response) => {
          this.$eventBus.$emit("cancelled");
          this.cancelLoading = false;
          this.dialog5 = false;
          this.status = -1;
          this.closeCancelAssignment();
        },
        (error) => {
          this.cancelLoading = false;
          this.error = this.$eventBus.parse(error);
        }
      );
    },
    closeCancelAssignment() {
      this.cancellationReason = null;
      this.confirmationDialog.cancelAssignment = false;
      this.cancelType = "";
    },
    reset() {
      this.readOnly = true;
      this.updateInfo = Object.assign(
        {},
        {
          sourceLocation: this.detail.sourceLocation,
          sourceCity: this.detail.sourceCity,
          sourceCountry: this.detail.sourceCountry,
          destinationLocation: this.detail.destinationLocation,
          destinationCity: this.detail.destinationCity,
          destinationCountry: this.detail.destinationCountry,
        }
      );
    },
    updateLocation() {
      this.loading = true;
      const { assignmentId } = this.detail;
      const {
        sourceLocation,
        sourceCity,
        sourceCountry,
        destinationLocation,
        destinationCity,
        destinationCountry,
      } = this.updateInfo;

      this.axios
        .post(`${this.constants.serverUrl}/api/assignments/update`, {
          assignmentId,
          sourceLocation,
          sourceCity,
          sourceCountry,
          destinationLocation,
          destinationCity,
          destinationCountry,
        })
        .then(
          (response) => {
            const { status, data } = response.data;
            this.updateInfo = Object.assign(
              {},
              {
                sourceLocation,
                sourceCity,
                sourceCountry,
                destinationLocation,
                destinationCity,
                destinationCountry,
              }
            );
            this.loading = false;
            this.readOnly = true;
          },
          (error) => {
            this.error = this.$eventBus.parse(error);
            this.loading = false;
          }
        );
    },
    changeLocation() {
      this.error = null;
      const {
        sourceLocation,
        sourceCity,
        sourceCountry,
        destinationLocation,
        destinationCity,
        destinationCountry,
      } = this.detail;
      this.updateInfo = Object.assign(
        {},
        {
          sourceLocation,
          sourceCity,
          sourceCountry,
          destinationLocation,
          destinationCity,
          destinationCountry,
        }
      );
      this.readOnly = false;
    },
    completeAssignment() {
      this.error = null;
      this.loading = true;
      const { serverUrl } = this.constants;
      let url = completeAssignments;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      const data = {
        assignmentId: this.detail.assignmentId.toString(),
      };

      //  this.$eventBus.$emit("assignment-completed");
      this.axios.post(this.constants.apiUrl + url, data, config).then(
        (response) => {
          const { status } = response.data;
          this.$eventBus.$emit("assignment-completed");
          this.status = 3;
          this.loading = false;
        },
        (error) => {
          this.error = this.$eventBus.parse(error);
          this.loading = false;
        }
      );
    },
    onDriverAssign({ driverId, driverName }) {
      if (this.detail.completedCheckList.length) {
        this.status = 5;
      } else {
        this.status = 2;
      }
      this.driverId = driverId;
      this.driverName = driverName;
    },
    removeDriverAssignment() {
      if (!this.driverId) {
        return;
      }
      this.unassignLoading = true;

      let url = removeDriver;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      const data = {
        driverId: this.driverId.toString(),
        assignmentId: this.detail.assignmentId.toString(),
      };
      this.axios.post(this.constants.apiUrl + url, data, config).then(
        (response) => {
          this.$eventBus.$emit("unassigned-completed");

          this.status = 1;
          this.driverId = null;
          this.driverName = null;
          this.unassignLoading = false;
          this.confirmationDialog.removeDriverAssignment = false;
        },
        (error) => {
          this.error = this.$eventBus.parse(error);
          this.unassignLoading = false;
        }
      );
    },
    removeTransporterAssignment() {
      if (
        this.detail.assignmentStatus == 2 ||
        this.detail.assignmentStatus == 5 ||
        this.detail.assignmentStatus == 6
      ) {
        this.error = "Please unassign the driver first !";
        this.unassignLoading = false;
        return;
      }
      this.unassignLoading = true;
      let url = "/dmsAdmin/unassignTransporter";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      const data = {
        assignmentId: this.detail.assignmentId.toString(),
      };
      this.axios.post(this.constants.apiUrl + url, data, config).then(
        (response) => {
          this.$eventBus.$emit("unassigned-completed");
          this.status = 1;
          this.driverId = null;
          this.driverName = null;
          this.unassignLoading = false;
          this.confirmationDialog.removeTransporterAssignment = false;
        },
        (error) => {
          this.error = this.$eventBus.parse(error);
          this.unassignLoading = false;
        }
      );
    },
    makePayment() {
      this.status = 4;
      this.confirmationDialog.makePayment = false;
      this.showPaymentSuccess = true;
    },
  },
};
</script>
<style lang="scss">
.fonttime {
  font-size: 15px;
}
.fonttime .dropdown.drop-down {
  top: -14%;
}
.pa-38 {
  padding: 38px;
}
div ::-webkit-scrollbar-thumb {
  background: darkorange;
}
div ::-webkit-scrollbar {
  width: 10px;
}
::-webkit-input-placeholder {
  /* Edge */
  font-size: 12px;
  padding-top: 18px;
  font-family: "Open Sans", sans-serif;
  color: rgba(0, 0, 0, 0.54);
}
.active {
  background-color: darkorange !important;
}

.vue__time-picker input.display-time {
  border-radius: 0px !important;
  border-bottom: 1px solid #d2d2d2 !important;
  border: none;
  padding: 0px !important;
}
</style>

<style scoped>
.w1300px {
  max-width: 1300px;
}
.divider {
  width: 100px;
}
.p-r-25 {
  padding-right: 25px;
}
.location {
  align-self: flex-start;
}
.v-stepper {
  width: 100%;
}

.step-clickable .primary {
  background-color: rgba(0, 0, 0, 0.38) !important;
  border-color: rgba(0, 0, 0, 0.38) !important;
}
.widthIcon {
  margin-left: 184px;
}
.v-stepper__step {
  /* padding:10px!important; */
  -webkit-box-align: center !important;
  border: 2px solid #00000029 !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  padding: 10px !important;
  margin: 5px 20px !important;
  position: relative !important;
  min-width: 200px !important;
}
.v-stepper__header {
  flex-wrap: nowrap;
  overflow: auto;
  height: 150px !important;
}
.v-stepper__step__step.primary {
  background: rgba(0, 0, 0, 0.38) !important;
}
.v-stepper__step--complete > .v-stepper__step__step.primary {
  background: green !important;
}
.theme--light.v-stepper {
  background: transparent !important;
  box-shadow: none !important;
}
.spacerclass {
  max-height: 550px !important;
}
.spMargin {
  margin-top: -21px;
}
</style>
